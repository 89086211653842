<template>
  <div class="radar-chart-container" v-if="this.current_cluster">
    <RadarChart :chart-data="datacollection" :component="component" :style="styles" ref="chart"
                @label-click="clickLabel"></RadarChart>
  </div>
</template>

<script>
import RadarChart from '@/components/reporting/charts/RadarChart.js';
import colors from '@/translation/cluster_colour_codes.js'

export default {
  name: 'RadarComponent',
  components: {
    RadarChart
  },
  props: ['component', 'dataset', 'current_cluster', 'expand'],
  data() {
    return {
      datacollection:{ labels:[], datasets: [] },
      styles: {
        height: '100%',
        width: '100%',
        position: 'relative',
        cursor: 'pointer'
      }
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      let data = [];
      let sum_of_data = 0;
      let active_clusters;
      // If the 'Total' Cluster is select, show all of the clusters
      if (this.$store.state.dashboard.selectedCluster === 'total') {
        active_clusters = Object.keys(this.dataset.cluster_information).filter(x => x !== 'total').map(
            key => this.dataset.cluster_information[key])
      } else {
        active_clusters = [this.current_cluster]
      }

      this.datacollection = {
        labels: this.component['data-labels'],
        datasets: active_clusters.map((cluster) => {
          let clusterId = this.getClusterId(cluster)
          return {
            data: this.component['data-sources'].map(dataSourceName => {
              // Always use ALL clusters for determining ranges
              let clusters = Object.keys(this.dataset.cluster_information).filter(x => x !== 'total')
              let maxValue = Math.max(...clusters.map(clusterId => this.dataset.cluster_information[clusterId][dataSourceName]))
              if (cluster.hasOwnProperty(dataSourceName)) {
                return ((cluster[dataSourceName] / maxValue) * 100).toFixed(0)
              } else {
                return 0
              }
            }),
            // Note: This isn't used by ChartJS, we use it for the values in the Tooltip labels.
            raw_data: this.component['data-sources'].map(dataSourceName => cluster[dataSourceName]),
            backgroundColor: [
                colors[clusterId]
            ],
            borderWidth: 1,
            label: 'Cluster ' + (parseInt(clusterId) + 1)
          }
        })
      }
      // Add an 'Average' Dataset if we're only showing a single cluster
      if (this.datacollection.datasets.length === 1) {
        let clusters = Object.keys(this.dataset.cluster_information).filter(x => x !== 'total')
        // Calculate the Average values across datasets
        let raw_data = this.component['data-sources'].map(dataSourceName => {
            let totalValue = clusters.reduce((sum, clusterId) => {
              if (this.dataset.cluster_information[clusterId].hasOwnProperty(dataSourceName)) {
                return sum + this.dataset.cluster_information[clusterId][dataSourceName]
              } else {
                return sum
              }
            }, 0)

            return totalValue / clusters.length
          })

        let avg_dataset = this.component['data-sources'].map((dataSourceName, idx) => {
              // Always use ALL clusters for determining ranges
              let maxValue = Math.max(...clusters.map(clusterId => this.dataset.cluster_information[clusterId][dataSourceName]))

              return ((raw_data[idx] / maxValue) * 100).toFixed(0)
            })

        this.datacollection.datasets.push({
          data: avg_dataset,
          raw_data: raw_data,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          borderWidth: 1,
          label: 'Average'
        })
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    getClusterId(cluster) {
      return Object.keys(this.dataset.cluster_information).filter(key => this.dataset.cluster_information[key] === cluster)
    },
    clickLabel(label) {
      this.$emit('label-click', label)
      if (this.component.hasOwnProperty('options') &&
          this.component.options.hasOwnProperty('enable-metric-links') &&
          this.component.options['enable-metric-links'] === true) {
        let reportId = this.$store.state.dashboard.currentDataId
        let metricName = label.toLowerCase().replace(' ', '-')
        this.$router.push('/home/reports/' + reportId + '/' + metricName)
      }
    }
  },
  watch: {
    current_cluster: function () {
      this.fillData()
    },
    expand: function (expand) {
        this.$refs.chart.options.legend.display = expand
    }
  }
};
</script>

<style>
.radar-chart-container {
  height: 100%;
  width: 100%;
}
</style>
