<template>
  <div class="flatscatter-chart-container" v-if="this.current_cluster">
    <Scatter :chart-data="datacollection" :component="this.component" :styles="styles" ref="chart"></Scatter>
  </div>
</template>

<script>
import Scatter from '@/components/reporting/charts/ScatterChart.js';
import images from '@/components/reporting/charts/MarkerImages.js'
import cluster_colour_codes from "../../translation/cluster_colour_codes";

export default {
  name: 'FlatScatter',
  components: {
    Scatter
  },
  props: ['component', 'dataset', 'current_cluster', 'expand'],
  data() {
    return {
      resizePending: false,
      styles: {
        height: '100%',
        width: '100%',
        position: 'relative'
      },
    };
  },
  computed: {
    datacollection(){
      let dataLabels = []
      let datasets = []

      // Cluster Comparison mode
      if (this.component.hasOwnProperty('mode') && this.component.mode === 'cluster-compare') {

        for (let cluster in this.$store.state.dashboard.data.cluster_information) {
          let y = (this.$store.state.dashboard.data.cluster_information[cluster] === this.current_cluster) * 0.5
          let iconTxt
          if (isNaN(parseInt(cluster))) {
            iconTxt = cluster[0].toUpperCase()
          } else {
            iconTxt = parseInt(cluster) + 1
          }
          datasets = datasets.concat(this.getDatapoints(this.$store.state.dashboard.data.cluster_information[cluster],
              this.component['data-sources'], [cluster], y ,
              cluster_colour_codes[cluster], '#5665FCFF', [iconTxt]))

          dataLabels.push('Cluster ' + iconTxt)
        }
      } else { // 'default' mode
        dataLabels = this.component['data-labels']

        datasets =  this.getDatapoints(this.current_cluster, this.component['data-sources'], this.component['data-labels'], 0,
            '#1420AFFF', '#5665FCFF', this.component['data-icon'])
      }

      // // Handle Highlighted
      if (this.component['highlight-data-sources']) {
        dataLabels = dataLabels.concat(this.component['highlight-data-labels'])
        datasets = datasets.concat(this.getDatapoints(this.current_cluster, this.component['highlight-data-sources'], this.component['highlight-data-labels'],
            0.5, '#717eff', '#1420AFFF', this.component['highlight-data-icons']
        ))
      }

      return {
        labels: dataLabels,
        datasets: datasets
      }
    }
  },
  methods: {
    getDatapoints(cluster, sources, labels, yPosition, pointFillColour, pointStrokeColour, iconTexts = null) {
      let datasets = [];

      if (!iconTexts) {
        iconTexts = sources.map((x, idx) => idx.toString())
      }

      if (!pointFillColour) {
        pointFillColour = '#1420AFFF'
      }
      // Handle Normal Data Sources
      for (let [idx, statistic] of sources.entries()) {
        datasets.push({
          label: [labels[idx]],
          data: [{x: cluster[statistic], y: yPosition}],
          // pointStyle: images.number_images[imgIdx],
          pointStyle: images.generateSVGIcon(200, 200, pointStrokeColour, pointFillColour,
              'white', iconTexts[idx], 5),
          pointRadius: 1,
          pointScale: 1.1,
          backgroundColor: [
            pointFillColour
          ],
          borderColor: [
            pointStrokeColour
          ]
        });
      }

      return datasets
    }
  },
  watch: {
    datacollection () {
      // If we update the graph data it will redraw, but not trigger the icon resizing function (since the ChartJS
      // point scaling doesn't work for images), we need to trigger it manually.
      if (!this.resizePending)
        this.resizePending = true
      this.$nextTick(() => {
        this.$refs.chart.resizePoints(this.$refs.chart.$el.clientWidth, this.$refs.chart.chartData.datasets)
        this.resizePending = false
      })

    },
    expand: function (expand) {
      this.$refs.chart.options.legend.display = expand
    }
  },
};
</script>

<style>
.flatscatter-chart-container {
  width: 100%;
  height: 100px;
  overflow-y: hidden;

  /*max-width: 800px;*/
  /*max-height: 500px;*/
  /*margin: auto;*/
}
</style>
