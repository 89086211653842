<template>
  <div class="table_cell">
    <b-table striped hover responsive
             :fields="fields"
             :items="ranked_data">
      <template v-slot:cell(value)="row">
        {{formatValues(row.item.value)}}
        <span v-if="row.item.hasOwnProperty('avg') && row.item.avg > row.item.value" class="caret-down">&#9660;</span>
        <span v-if="row.item.hasOwnProperty('avg') && row.item.avg < row.item.value" class="caret-up">&#9650;</span>
        <span v-if="row.item.hasOwnProperty('avg') && row.item.avg === row.item.value" class="caret-equal">&#8212;</span>
      </template>
    </b-table>
  </div>
</template>

<script>

import human_readable_strings from '@/translation/human_readable_names.js';

export default {
  name: 'TableComponent',
  props: ['component', 'dataset', 'current_cluster'],
  data(){
      return {
          table_label: "Occupation",  // TODO this should be a schema parameter
      }
  },
  mounted() {
    if (this.component.hasOwnProperty('options') && this.component.options.hasOwnProperty('table-label')) {
      this.table_label =  this.component.options['table-label']
    }
  },
  computed: {
    fields() {
      let fields = [
        { key: 'rank', label: 'Rank'},
        { key: 'label', label: this.table_label},
        { key: 'value', label: 'Value', formatter: this.formatValues,
          tdClass: 'rank-table-values', thClass: 'rank-table-values'},
      ]
      if (this.$store.state.dashboard.selectedCluster !== 'total') {
        fields.push({ key: 'avg', label: 'Avg', formatter: this.formatValues,
          tdClass: 'rank-table-values', thClass: 'rank-table-values'})
      }
      return fields
    },
    ranked_data() {
      let data = {};
      for (let statistic of this.component['data-sources']) {
        if (this.current_cluster.hasOwnProperty(statistic)) {
          data[statistic] = this.current_cluster[statistic];
        } else {
          data[statistic] = 0
        }
      }
      let ranked_data_array = [];
      const ranked_values = this.sort_dictionary(data);
      for (let i = 0; i < this.component['n_rankings']; i++) {
        let key = human_readable_strings[ranked_values[i][0]];
        let value = ranked_values[i][1].toLocaleString(undefined, {
          maximumFractionDigits: 0
        });
        let row = {
          rank: i + 1,
          label: key,
          value: value
        }
        if (this.$store.state.dashboard.selectedCluster !== 'total') {
          if (this.dataset.cluster_information.total.hasOwnProperty(ranked_values[i][0])) {
            row['avg'] = this.dataset.cluster_information.total[ranked_values[i][0]].toFixed(0)
          } else {
            row['avg'] = 0
          }
        }
        ranked_data_array.push(row);
      }
      return ranked_data_array;
    },
  },
  methods: {
    sort_dictionary(dict) {
      // Create items array
      var items = Object.keys(dict).map(function(key) {
        return [key, dict[key]];
      });

      // Sort the array based on the second element
      items.sort(function(first, second) {
        return second[1] - first[1];
      });
      return items;
    },
    formatValues (x) {
      // TODO - Maybe add options for different types of values?
      return `${x}%`
    }
  },

};
</script>

<style></style>
