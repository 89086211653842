<template>
  <div class="horizontal-chart-container" v-if="this.current_cluster">
    <BubbleScatterChart :chart-data="datacollection" :component="component" :options="options"></BubbleScatterChart>
    <p v-if="component['size-feature']">Size feature: {{component['size-feature']}} (normalised)</p>
  </div>
</template>

<script>
import BubbleScatterChart from '@/components/reporting/charts/BubbleScatterChart.js';
import colours from '@/translation/cluster_colour_codes.js';

export default {
  name: 'BubbleScatterComponent',
  components: {
    BubbleScatterChart
  },
  props: ['component', 'dataset', 'current_cluster'],
  data() {
    return {
      datacollection: { labels: [], datasets: [] },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'probability2'
              }
            }
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'time2'
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              var label = data.labels[tooltipItem[0].index];
              return `Cluster ${label}`;
            },
            label: function(tooltipItem) {
              return `(${tooltipItem.xLabel.toFixed(1)}, ${tooltipItem.yLabel.toFixed(1)})`;
            }
          },
          backgroundColor: '#FFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false
        }
      }
    };
  },
  mounted() {
    this.fillData();
    // TODO If the option is "select-dataset", then show a dropdown dataset selector, do this for x, y, r and c
  },
  methods: {
    fillData() {
      console.log('Filling data for bubble chart:');
      console.log(this.component);

      let data = [];
      let background_colors = [];
      let sum_of_data = 0;
      let maxiumum_r_value = 0;
      let minimum_r_value = 0;
      let labels = [];

      console.log('Setting axes names');
      // TODO: Make these appear on the chart (not easy!)
      this.options.scales.xAxes[0].scaleLabel.labelString = this.component[
        'x-axis'
      ];
      this.options.scales.yAxes[0].scaleLabel.labelString = this.component[
        'y-axis'
      ];

      for (let cluster_number in this.dataset['cluster_information']) {
        console.log(`Cluster ${cluster_number}`);
        let row = {
          x: this.dataset['cluster_information'][cluster_number][
            this.component['x-axis']
          ],
          y: this.dataset['cluster_information'][cluster_number][
            this.component['y-axis']
          ]
        };
        // TODO: Radius by feature, and scaled appropriately
        if (this.component['size-feature']) {
          row['r'] = this.dataset['cluster_information'][cluster_number][
            this.component['size-feature']
          ];
        } else if (this.component['size-static']) {
          row['r'] = this.component['size-static'];
        } else {
          row['r'] = 10;
        }
        maxiumum_r_value = Math.max(maxiumum_r_value, row['r']);
        minimum_r_value = Math.min(minimum_r_value, row['r']);
        data.push(row);

        if (cluster_number === 'total') {
          background_colors.push('rgb(82, 82, 82)');
        } else {
          background_colors.push(colours[cluster_number]);
        }

        labels.push(cluster_number);
      }

      // normalise r values. 45 seemed like a good value, TODO parameterise the domain of scaled values
      let maximum_bubble_size = 45;
      let minimum_bubble_size = 20;
      let bubble_size_range = maximum_bubble_size - minimum_bubble_size;
      for (let i = 0; i < data.length; i++) {
        let ratio =
          (data[i]['r'] - minimum_r_value) /
          (maxiumum_r_value - minimum_r_value);
        data[i]['r'] = minimum_bubble_size + bubble_size_range * ratio;
      }

      if (this.component['data-type'] === 'percentage') {
        for (let i = 0; i < data.length; i++) {
          data[i] = ((100 * data[i]) / sum_of_data).toFixed(0);
        }
      }
      this.datacollection = {
        datasets: [
          {
            backgroundColor: background_colors,
            data: data,
          }
        ],
        labels: labels
      };
      console.log(data);
    }
  }
};
</script>

<style>
.horizontal-chart-container {
  width: 100%;
  max-width: 800px;
  max-height: 500px;
  margin: auto;
}
</style>
