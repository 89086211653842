import { Bubble, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;


export default {
  extends: Bubble,
  mixins: [reactiveProp],
  props: ['component', 'options'],
  data() {
    return {}
  },
  watch: {
    chartData() {
      this.update_chart();
    }
  },
  methods: {
    update_chart() {
      this.$data._chart.destroy()
      console.log("Updating bubble:")
      console.log(this.options)
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  }
};
