<template>
  <div>
    <HorizontalComparisonChart
      :chart-data="datacollection"
      :is_percentage="false"
    ></HorizontalComparisonChart>

    <b-table-simple striped hover caption-top v-if="model">
      <caption>
        Enter features and weights for your model:
      </caption>
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th :colspan="dataset['cluster_information'].length" class="data_cell">Results</b-th>
        </b-tr>
        <b-tr>
          <b-th>Statistic and Weight</b-th>
          <b-th
            v-for="(cluster, cluster_num) in this.dataset[
              'cluster_information'
            ]"
            :key="cluster_num"
            class="data_cell"
          >
            Cluster {{ cluster_num }}
          </b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(row, row_num) in model" v-bind:key="row_num">
          <b-td>
            <b-form inline>
            <b-form-select
              v-model="model[row_num][0]"
              :options="dataset['metadata']['datasets']"
            />
            <b-input v-model="model[row_num][1]" class="weight_input" />
            </b-form>
          </b-td>
          <b-td
            v-for="(cluster, cluster_num) in dataset['cluster_information']"
            :key="cluster_num"
            class="data_cell"
          >
            {{
              (
                dataset['cluster_information'][cluster_num][model[row_num][0]] *
                model[row_num][1]
              ).toFixed(2)
            }}
          </b-td>
          <b-td>
            <b-button @click="remove_row(row_num)" variant="outline-danger"
              >X</b-button
            ></b-td
          >
        </b-tr>
        <b-tr v-if="show_results_row">
          <b-td></b-td>
          <b-td>Results:</b-td>
          <b-td
            v-for="(cluster, cluster_num) in dataset['cluster_information']"
            :key="cluster_num"
          >
            {{ linear_model_results[cluster_num] }}
          </b-td>
          <b-td></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-button @click="add_row()" variant="success">Add Row</b-button>
    <b-button @click="reset_model()" variant="danger">Reset</b-button>
  </div>
</template>

<script>
import HorizontalComparisonChart from '@/components/reporting/charts/HorizontalComparisonChart.js';
import colours from '@/translation/cluster_colour_codes.js';

export default {
  name: 'LinearModelComponent',
  props: ['component', 'dataset'],
  components: {
    HorizontalComparisonChart
  },

  data() {

    return {
      model: [],
      show_results_row: false // Probably not worth exposing this to uses
    };
  },

  computed: {
    linear_model_results() {
      let results = {};
      for (let cluster_number in this.dataset['cluster_information']) {
        let total = 0;
        for (let row of this.model) {
          let statistic = row[0];
          let weight = row[1];
          let value = this.dataset['cluster_information'][cluster_number][
            statistic
          ];
          if (value) {
            total += weight * value;
          }
        }
        results[cluster_number] = total;
      }

      return results;
    },
    datacollection() {
      let data = [];
      let labels = [];

      for (let cluster_number in this.linear_model_results) {
        data.push(this.linear_model_results[cluster_number]);
        labels.push(`Cluster ${cluster_number}`);
      }
      return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: colours
          }
        ]
      };
    }
  },

  methods: {
    reset_model() {
      this.model = [];
      for (let row of this.component['default_model']) {
        this.model.push([row[0], row[1]]);
      }
    },

    add_row() {
      this.model.push(['NewStatistic', 1]);
    },

    remove_row(delete_row_number) {
      let new_model = [];

      for (let row_number in this.model) {
        if (row_number == delete_row_number) {
          continue;
        }
        let row = this.model[row_number];
        new_model.push([row[0], row[1]]);
      }
      this.model = new_model;
    }
  },

  mounted() {
    this.reset_model();
  }
};
</script>

<style scoped>
.weight_input {
  width: 80pt;
  text-align: right;
}

.data_cell{
  text-align: right;
}
</style>

