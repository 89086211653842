<template>
  <b-col
    class="statistic flex-grow-1 flex-fill"
    :md="col_size_md"
    :sm="col_size_sm"
    :id="'statistic_' + statistic['id']"
    v-if="current_cluster"
    :class="{'square': square }"
  >
    <div
        v-if="statistic['title'] && titleLocation === 'external'"
        class="statistic-heading"
    ><h3>{{ statistic['title'] }}</h3>
    </div>
    <div :class="{'backdrop-shadow': expand }" @click="expand=false"></div>
    <div class="reboundary-card" :class="{'hyperlink': hyperlink, 'expanded': expand, 'square': square, 'expanded-height': expandHeight }"
         @click="clickWidget">

      <div
          v-if="statistic['title'] && titleLocation === 'internal'"
          class="statistic-heading"
      >
        {{ statistic['title'] }}
      </div>
      <div :class="flow_direction">
<!--        Normal Components (Shown all the time)-->
        <div
            v-for="(component, i) in statistic['components']"
            v-bind:key="i"
            class="component"
        >
          <ComponentPlacer
              :component="component"
              :dataset="dataset"
              :current_cluster="current_cluster"
              :current_cluster_id="current_cluster_id"
              :debug_mode="debug_mode"
              :statistic="statistic"
              :expand="expand"
          ></ComponentPlacer>
        </div>
<!--        Expanded-Mode only components-->
        <div v-if="expand">
          <div
              v-for="(component, i) in statistic['expand-components']"
              v-bind:key="i+'-expanded'"
              class="component"
          >
            <ComponentPlacer
                :component="component"
                :dataset="dataset"
                :current_cluster="current_cluster"
                :current_cluster_id="current_cluster_id"
                :debug_mode="debug_mode"
                :statistic="statistic"
                :expand="expand"
            ></ComponentPlacer>
          </div>
        </div>

<!--        Simple Expanded Description (text Only)-->
        <div v-if="expand && statistic.description" class="expanded-description">
          {{statistic.description}}
        </div>
        <div class="statistic-controls" v-if="!statistic['hide_controls']">
          <font-awesome-icon v-if="getSources(statistic).length"
              icon="database" size="1x" @click="sourcesStatistic=statistic" v-b-modal.showSources/>
          <font-awesome-icon icon="compress-alt" v-if="expand" @click="clickToggleExpand"></font-awesome-icon>
          <font-awesome-icon icon="expand" v-if="!expand" @click="clickToggleExpand"></font-awesome-icon>
        </div>
      </div>
    </div>
    <b-modal v-if="sourcesStatistic" id="showSources" :title="`Sources`" ok-only>
      <ul>
        <li v-for="dataset_source in getSources(sourcesStatistic)" v-bind:key="dataset_source[0]">
          <b>{{dataset_source[0]}}</b> - {{dataset_source[1]}}
        </li>
      </ul>
    </b-modal>
  </b-col>
</template>

<script>
import ComponentPlacer from '@/components/ComponentPlacer.vue';

export default {
  name: 'DashboardComponent',
  props: [
    'statistic',
    'dataset',
    'current_cluster',
    'current_cluster_id',
    'debug_mode'
  ],
  components: {
    ComponentPlacer,
  },
  data() {
    return {
      expand: false,
      hyperlink: false, // Toggle Metric Hyperlinks,
      square: false,
      titleLocation: 'internal',
      expandHeight: false,
      aspectRatio: null,
      sourcesStatistic: null,  // which component is selected, for showing sources
    };
  },
  computed: {
    flow_direction: function () {
      if (this.statistic['flow']) {
        return `${this.statistic['flow']}_statistic`;
      } else {
        return 'vertical_statistic';
      }
    },
    col_size_md() {
      if (this.statistic['col-size']) {
        if (this.statistic['col-size'] >= 4) {
          return this.statistic['col-size'];
        }
      }
      return 4;
    },
    col_size_sm() {
      let size_sm = this.col_size_md * 2;
      if (size_sm > 12) {
        size_sm = 12;
      }
      return size_sm;
    }
  },
  mounted() {
    // Process Stat Options
    if (this.statistic.hasOwnProperty('options')) {
      // Optional Hyperlink
      if (this.statistic.options.hasOwnProperty('hyperlink')) {
        this.hyperlink = this.statistic.options.hyperlink
      }
      // Optional Square-Formatting
      if (this.statistic.options.hasOwnProperty('force-square')) {
        this.square = this.statistic.options['force-square']
      }
      // Optional Title Placement (internal or external)
      if (this.statistic.options.hasOwnProperty('title-placement')) {
        this.titleLocation = this.statistic.options['title-placement']
      }
      // optional hide title (overrides title location)
      if (this.statistic.options.hasOwnProperty('hide-title')) {
        this.titleLocation = 'none'
      }
      // Option - Force the container's height to expand when the width is expanded (i.e. The Expand icon is clicked)
      if (this.statistic.options.hasOwnProperty('force-expand-height')) {
        this.expandHeight = this.statistic.options['force-expand-height']
      }
      // Option - Force container aspect ratio
      if (this.statistic.options.hasOwnProperty('aspect-ratio')) {
        this.aspectRatio = this.statistic.options['aspect-ratio']
      }
    }
  },
  methods: {
    clickWidget () {
      // Note: We're currently handling these as RELATIVE links
      if (this.hyperlink) {
        this.$router.push((this.$route.path + '/' + this.hyperlink).replace('//', '/'))
      }
    },
    clickToggleExpand (event) {
      this.expand = !this.expand
      event.stopPropagation()
    },
    getSources(statistic){
      let datasets = [];

      statistic['components'].forEach((component) => {
        if (component['data-sources']){
          component['data-sources'].forEach((dataset) => {
            datasets.push([dataset, this.getSource(dataset)])
          })
        }
      })

      return datasets
    },
    getSource(dataset){
      // TODO: Actual sources
      return "ABS Census Data"
    }
  }
};
</script>
<style scoped>
h2 {
  font-size: 32pt;
  text-align: center;
}

.statistic {
  overflow: hidden;
  padding: 1.5em;
}

.hyperlink:hover {
  cursor: pointer;
  box-shadow: 0px 0px 5px 4px rgb(9, 97, 169, 0.3);
}

.statistic-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(247, 247, 247, 0.76);
  margin: 8pt;
  padding: 12pt;
  height: 100%;
  align-content: center;
  overflow: hidden;
}

.vertical_statistic {
  /* Elements flow vertically */
  display: flex;
  flex-direction: column;
  height: calc(100% - 1.5em);
  width: 100%;
  /*align-items: center;*/
  justify-content: space-around;
  overflow: auto;
}

.square {
  aspect-ratio: 1/1;
}

.horizontal_statistic {
  /* Elements flow horizontally */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* NOTE: This forces the card to be square(ish) */
.reboundary-card {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.reboundary-card.square {
  overflow: hidden;
}

.component {
  padding: 5px;
  height: 100%;
}

.statistic-heading {
  justify-self: start;
  color: #014c7f;
  /*white-space: pre;*/
  font-size: 1.5em;
}

.statistic-controls {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 1em;
  top: 1em;
  font-size: 1.2em;
  color: #b6b6b6;
  cursor: pointer;
}

.statistic-controls svg {
  margin-left: 5px;
}

.statistic-controls svg:hover {
  color: black;
}

.expanded {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  z-index: 100;
  max-height: 75vh;
  /*box-shadow: 10px 10px 5px 0 rgba(0,0,0,0.5);*/
}

.expanded.expanded-height {
  height: 75vh;
}

.backdrop-shadow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0,0,0,0.3);
}

.expanded-description {
  align-self: center;
  background: rgba(4, 87, 217, 0.05);
  margin-bottom: 1em;
}

</style>

