<template>
  <div>
    <b-row>
      <b-col>
        <h1>Component Tester</h1>
        <b-form-group>
          <editor
            id="schema_editor"
            v-if="component"
            v-model="component['sub-component']"
            @init="editorInit"
            lang="json"
            theme="crimson_editor"
            height="300pt"
          ></editor>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <ComponentPlacer
        v-if="isJSONValid"
        :component="subcomponent"
        :dataset="dataset"
        :current_cluster="current_cluster"
        :current_cluster_id="current_cluster_id"
        :debug_mode="debug_mode"
        :statistic="statistic"
    ></ComponentPlacer>
     <b-alert variant="danger" :show="!isJSONValid">JSON is not valid. Please check above for errors and the component should update automatically.</b-alert>
    </b-row>
    <b-row>
      <b-button @click="logSubComponentToConsole()">Log Component to Console</b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ComponentTester',
  components: {
    editor: require('vue2-ace-editor'),
    ComponentPlacer: () => import('@/components/ComponentPlacer.vue')
  },
  props: [
    'statistic',
    'dataset',
    'current_cluster',
    'current_cluster_id',
    'debug_mode',
    'component'
  ],
  data() {
    return {};
  },
  methods: {
    editorInit: function() {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/json');
      require('brace/mode/less');
      require('brace/theme/crimson_editor');
    },
    logSubComponentToConsole(){
      console.log("Subcomponent:")
      console.log(this.subcomponent)
      return true
    }
  },
  computed: {
    subcomponent() {
      return JSON.parse(this.component['sub-component']);
    },
    isJSONValid(){
      try{
        JSON.parse(this.component['sub-component']);
        return true
      }catch (e){
        console.error("JSON isn't valid!", e)
        return false
      }

    }
  }
};
</script>

<style scoped>
.btn {
  margin: 8pt;
}
.results_parent {
  width: 100%;
  min-width: 400pt;
  margin: auto;
  padding: 16pt;
  background-color: #e9ecef;
}

#schema_editor {
  font-size: 16pt;
  width: 100%;
  min-width: 500pt;
  height: 600pt;
}

.pull_right {
  float: right;
}
</style>
