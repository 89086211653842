<template>
    <div class="report-parameters-component">
      <div class="precision-container">
        <div class="sub-heading">Precision</div>
        <div class="cluster-precision">
          <div>{{clusteringPrecision}}</div>
        </div>
      </div>
      <div class="data-set-container">
        <div class="sub-heading">Clustering Features</div>
        <div class="data-set-item-container">
          <div v-for="(feature, idx) of clusteringFeatures" v-bind:key="idx"
               class="rebutton-icon">
            <div class="icon">
              <font-awesome-icon icon="database" size="1x" />
            </div>
            {{feature}}
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: 'HighlightsComponent',
    props: ['component', 'dataset', 'current_cluster', 'statistic'],
    data() {
      return {
        precision: [],
        precisionLookup: {
          'Low': 'Low - 3 Clusters',
          'Medium': 'Medium - 5 Clusters',
          'High': 'High - 7 Clusters'
        }
      }
    },
    mounted () {
    },
  methods: {
  },
  computed: {
      clusteringFeatures () {
        return this.dataset.metadata.datasets_query_categories.map(name =>
          name[0].toUpperCase() + name.slice(1, ))
      },
    clusteringPrecision () {
        return this.precisionLookup[this.dataset.metadata.cluster_precision]
    }
  },
  watch: {
  }
}
</script>

<style scoped>

.report-parameters-component{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  overflow: auto;
  padding: 10px 20px;
}

.data-set-container {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.precision-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: stretch;
  justify-items: center;
  margin-right: 50px;
  border-right: 1px solid #bcbdc2;
  padding: 0 50px;
}

.data-set-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.rebutton-icon:hover {
  background: #e1e1ef;
  color: #005da7;
  cursor: default;
}

.precision-heading h5 {
  color:  #005da7;
  text-align: center;
}

.sub-heading {
  justify-self: center;
  text-align: center;
  color: #014c7f;
  /* white-space: pre; */
  font-size: 1.5em;
}

.cluster-precision {
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;
  font-size: 1.1em;
  color: #014c7f;
  justify-content: center;
  height: 100%;
}

</style>
