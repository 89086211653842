import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;


export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['component', 'statistic'],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let datasetName = data.datasets[tooltipItem.datasetIndex].label || '';
              let absValue = Math.round(data.datasets[tooltipItem.datasetIndex].absData[tooltipItem.index])
                if (
                        this.component['data-type'] &&
                          this.component['data-type'] === 'percentage'
                      ) {
                    let percentValue = Math.round(tooltipItem.yLabel)
                    return `${datasetName}: ${percentValue}% (${absValue}) `

                } else if (this.component['data-type'] &&
                          this.component['data-type'] === 'currency') {
                          let currencyValue = tooltipItem.yLabel.toFixed(2)
                    return `${datasetName}: $${currencyValue}`
                } else {
                    let value = Math.round(tooltipItem.yLabel)
                    return `${datasetName}: ${value}`
                }
            }
          }
        },
          legend: {
            display: false,
              position: 'right'
          }
      }
    }
  },
  mounted() {
      if (this.component.hasOwnProperty('chart-options')) {
          Object.keys(this.component['chart-options']).forEach(opt => {
              this.options[opt] = this.component['chart-options'][opt]
          })
      }
          // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
    watch: {
      // ChartJS does NOT dynamically update if the legend config changes, so we have to redraw manually.
      'options.legend.display': function() {
          this.renderChart(this.chartData, this.options);
      }
    }
};
