<template>
    <div class="highlights-component">
      <div v-for="(stat, idx) of stats" v-bind:key="idx" class="highlights-container">
        <div class="highlights-value">
          {{stat.value}}
        </div>
        <div class="highlights-text">
          {{stat.text}}
        </div>
      </div>
    </div>
</template>

<script>
/* Configurable 'Highlights' component.
// Config is via the 'stat_options' prop, which takes an array of
// objects of containing a key and a text field. Key should match a
// cluster_information prop name, while text is the description to be
 shown in the cell. */

export default {
    name: 'HighlightsComponent',
    props: ['component', 'dataset', 'current_cluster', 'statistic'],
    data() {
      return {
        stats: [],
        current_stat_options: [
          {
            key: 'Tot_P_P',
            text: 'Total Population',
            format: (x) => Math.round(x).toLocaleString()
          },
          {
            key: 'Median_age_persons',
            text: 'Median Age',
            format: (x) => Math.round(x).toLocaleString()
          },
          {
            key: 'Median_tot_prsnl_inc_weekly',
            text: 'Median Weekly Income',
            format: (x) => '$' + Math.round(x)
          },
          {
            key: 'Pcent_Employmnt_to_pop_P',
            text: 'Employment',
            format: (x) => Math.round(x).toLocaleString() + '%',
          },
            // TODO - Find a stat for Median/Avg Property Sale/Value
          // {
          //   key: 'Tot_P_P',
          //   text: 'Median Property Sale'
          // },
        ],
        // Some simple formatting for values
        formatters: {
          'number': (x) => Math.round(x).toLocaleString(),
          'percentage': (x) => Math.round(x).toLocaleString() + '%',
          'currency': (x) => '$' + Math.round(x).toLocaleString()
        }
      }
    },
    mounted () {
      // Replace the config if one is provided
      if (this.component && this.component.hasOwnProperty('data-sources')) {
        this.current_stat_options = this.loadConfig(this.component)
        console.log('Config: ', this.current_stat_options)
      }
      if (this.dataset) {
        this.loadStats(this.dataset.cluster_information.total)
      }
    },
  methods: {
      // Load Config from a Statistic
      loadConfig(component) {
        return component['data-sources'].map((dataset, idx) => {
          let stat = {
            key: dataset
          }
          // Assign a Label or Default to the Dataset Key
          if (component['data-labels'][idx]) {
            stat['text'] = component['data-labels'][idx]
          } else {
            stat['text'] = dataset
          }

          // Specify a formatter or default to 'number'
          if (component.hasOwnProperty('formatters') &&
              idx in component['formatters'] &&
              component['formatters'][idx] in this.formatters
          ) {
              stat['format'] = this.formatters[component['formatters'][idx]]
          } else {
            stat['format'] = this.formatters['number']
          }
          return stat
        })
      },
      loadStats(dataset) {
        this.stats = this.current_stat_options.map( stat => {
          // If a formatter was provided, use it.
          let val
          if (stat.format) {
            val = stat.format(dataset[stat.key])
          } else {
            val = dataset[stat.key]
          }
          return {
            value: val,
            text: stat.text
          }
        })
        console.log(this.stats)
      }
  },
  watch: {
      current_cluster: function (newCluster) {
        this.loadStats(newCluster)
    }
  }
}
</script>

<style scoped>

.highlights-component{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  overflow: auto;
}

.highlights-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #014c7f;
  border-right: 1px solid #bcbdc2;
  flex-grow: 1;
  padding: 10px 50px;
}

.highlights-container:last-of-type {
  border-right: none;
}

.highlights-value {
  font-size: 2.2em;
  font-weight: 600;
}


.highlights-text{

}
</style>
