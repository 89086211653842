<template>
    <div v-if="is_loading" class="loading-widget">
        <font-awesome-icon  icon="compass" spin  :size='sizeValue'/> <h3>{{ message }}</h3>
    </div>
</template>

<script>
export default {
    props: ['is_loading', 'message', 'size'],
  computed: {
      sizeValue() {
        return this.size || "4x"
      }
  }
}
</script>

<style scoped>
.loading-widget{
    width: 80%;
    text-align: center;
    margin: auto;
    color:#014c7f;
}
</style>
