<template>
  <div class="horizontal-chart-container">
    <HorizontalComparisonChart
      v-if="current_cluster_id !== 'total'"
      :chart-data="datacollection"
      :style="styles"
      :is_percentage="component['data-type'] && component['data-type']['percentage']"
    ></HorizontalComparisonChart>
    <h4 v-else>
      Select a cluster (other than total) to view its comparison.
    </h4>
  </div>
</template>

<script>
import HorizontalComparisonChart from '@/components/reporting/charts/HorizontalComparisonChart.js';

export default {
  name: 'HorizontalComparison',
  components: {
    HorizontalComparisonChart
  },
  props: ['component', 'dataset', 'current_cluster_id'],
  data() {
    return {
      datacollection2: { labels: [], datasets: [] },
      styles: {
        height: '100%',
        width: '100%',
        position: 'relative',
      },
    };
  },
  computed: {
    datacollection() {
      let green = '#20d34a';
      let red = '#aa3220';
      let grey = '#7a7a7a';

      let data = [];
      let colours = [];

      for (let statistic of this.component['data-sources']) {
        let A = this.dataset['cluster_information']['total'][statistic]; // Overall value of stat
        let C = this.dataset['cluster_information'][this.current_cluster_id][statistic]; // Statistic for current cluster
        let percentage_difference = (100 * (C - A)) / A;
        data.push(percentage_difference);
        if (percentage_difference > 0.5) {
          colours.push([green]);
        } else if (percentage_difference < -0.5) {
          colours.push([red]);
        } else {
          colours.push([grey]);
        }
      }
      return {
        labels: this.component['data-labels'],
        datasets: [
          {
            backgroundColor: colours,
            data: data
          }
        ]
      };
    }
  }
};
</script>

<style>
.horizontal-chart-container {
  height: 100%;
  width: 100%;
}
</style>
