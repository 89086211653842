<template>
  <div class="pie-chart-container" v-if="this.current_cluster">
    <PieChart :chart-data="datacollection" :component="this.component" :styles="styles" :legend="legend"
    ref="pieChart"></PieChart>
  </div>
</template>

<script>
import PieChart from '@/components/reporting/charts/PieChart.js';

export default {
  name: 'HorizontalComparison',
  components: {
    PieChart
  },
  props: ['component', 'dataset', 'current_cluster', 'expand'],
  data() {
    return {
      styles: {
        height: '100%',
        width: '100%',
        position: 'relative',
      },
      legend: {
        display: false
      }
    };
  },
  computed: {
    datacollection(){
      let data = [];
      let sum_of_data = 0;
      for (let statistic of this.component['data-sources']) {
        data.push(this.current_cluster[statistic]);
        sum_of_data += this.current_cluster[statistic];
      }

      if (this.component['data-type'] === 'percentage') {
        for (let i = 0; i < data.length; i++) {
          data[i] = ((100 * data[i]) / sum_of_data);
        }
      }
      return {
        labels: this.component['data-labels'],
        datasets: [
          {
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)'
            ],

            borderWidth: 1
          }
        ]
      };
    },
  },
  watch: {
    expand: function (newVal) {
      // Note - This doesn't work. The chart doesn't seem to notice the change

      this.legend.display = !!newVal;
    }
  }
};
</script>

<style>
.pie-chart-container {
  height: 100%;
  width: 100%;

  /*max-width: 800px;*/
  /*max-height: 500px;*/
  /*margin: auto;*/
}
</style>
