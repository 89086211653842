
const svgNS = "http://www.w3.org/2000/svg";
var imageData = [
    {
        path: 'scatter-icon-0.svg',
        key: '0'
    },
    {
        path: 'scatter-icon-1.svg',
        key: '1'
    },
    {
        path: 'scatter-icon-2.svg',
        key: '2'
    },
    {
        path: 'scatter-icon-3.svg',
        key: '3'
    },
    {
        path: 'scatter-icon-4.svg',
        key: '4'
    },
    {
        path: 'scatter-icon-5.svg',
        key: '5'
    },
    {
        path: 'scatter-icon-6.svg',
        key: '6'
    },
    {
        path: 'scatter-icon-7.svg',
        key: '7'
    },
    {
        path: 'scatter-icon-8.svg',
        key: '8'
    },
    {
        path: 'scatter-icon-9.svg',
        key: '9'
    },
    {
        path: 'scatter-icon-average.svg',
        key: 'avg'
    },
]


function getImage(imgName) {
    return require('@/assets/img/' + imgName)
}
// var averageFile = require('@/assets/img/scatter-icon-average.svg')
//
// let averageImage = new Image()
// averageFile.src = averageFile

let images = {}

imageData.forEach(imgConfig => {
    let i = new Image()
    i.src = getImage(imgConfig.path)
    i.height = '52'
    i.width = '52'
    images[imgConfig.key] = i
})

export default {

    images: images,
    number_images: [
        images[0],
        images[1],
        images[2],
        images[3],
        images[4],
        images[5],
        images[6],
        images[7],
        images[8],
        images[9]
    ],
    generateSVGIcon (width, height, strokeColour, fillColour, textColour, svgText, strokeWidth) {
        /***
         * Create an SVG Image using the provided Data.
         * @type {SVGSVGElement}
         */
        let svg = document.createElementNS(svgNS, "svg");
        svg.setAttribute('xmlns', svgNS)
        svg.setAttribute('width', "200")
        svg.setAttribute('height', "200")
        svg.setAttribute('viewBox', '0 0 200 200')
        svg.setAttribute('fill', 'none')

        let circle = document.createElementNS(svgNS, "circle");
        // circle.setAttribute('xmlns', svgNS)

        circle.setAttribute('cx', width / 2)
        circle.setAttribute('cy', height / 2)
        let radius = (height / 2) - (strokeWidth * 2)
        circle.setAttribute('r', radius)
        circle.setAttribute('fill', fillColour)
        circle.setAttribute('stroke', strokeColour)
        circle.setAttribute('stroke-width', strokeWidth)
        svg.appendChild(circle)


        var myTextElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
        var myText = document.createTextNode(svgText);
        myTextElement.setAttribute("x", width / 2);

        // HACK - Firefox doesn't support vertical centering in SVGs, apparently.
        var isFirefox = typeof InstallTrigger !== 'undefined'

        var fontSize = Math.max((100 - (svgText.toString().length * 10)), 50)
        if (isFirefox) {
            myTextElement.setAttribute("y", (height / 2) + (fontSize / 2) - 10)
        } else {
            myTextElement.setAttribute("y", height / 2)
        }
        myTextElement.setAttribute("text-anchor","middle")
        myTextElement.setAttribute("alignment-baseline", "middle")

        myTextElement.setAttribute("fill", textColour);
        myTextElement.setAttribute("font-family", "'Montserrat', sans-serif");


        myTextElement.setAttribute("font-size",  fontSize + 'px')
        myTextElement.setAttribute("font-weight", "800");

        myTextElement.appendChild(myText);
        svg.appendChild(myTextElement);

        // let URL = window.URL || window.webkitURL || window;
        var svgString = svg.outerHTML
        var dataUrl = 'data:image/svg+xml,' + encodeURIComponent(svgString);
        let img = new Image()
        img.src = dataUrl
        return img
    }


}
