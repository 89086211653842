import { Scatter, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Scatter,
  mixins: [reactiveProp],
  props: ['component'],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
            position: 'right'
        },
          elements: {
            point: {
                radius: 5
            }
          },
          scales: {
              yAxes: [{
                  grid: {

                  },
                  gridLines: {
                      display: true,
                      drawBorder: false, //<- set this
                      drawTicks: false,
                      lineWidth: 0,
                      zeroLineWidth: 1
                  },
                  scaleLabel: {
                      display: false
                  },
                  ticks: {
                      display: false,
                      min: 1,
                      max: 0,
                      maxTicksLimit: 1
                  }
              }],
              xAxes: [{
                  gridLines: {
                      display: false,
                      drawBorder: true, //<- set this
                      drawTicks: true,
                  },
                  ticks: {
                      autoSkip: false,
                      maxRotation: 90,
                      minRotation: 90
                  }
              }]
          },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
                let label = data.labels[tooltipItem.datasetIndex] + ": "
                if (
                    this.component['data-type'] && this.component['data-type'] === 'percentage') {
                    label += Math.round(tooltipItem.xLabel)
                    label = label + '%';
                } else if (this.component['data-type'] && this.component['data-type'] === 'currency') {
                    label += '$' + tooltipItem.xLabel.toFixed(2)
                } else {
                    label += tooltipItem.xLabel.toFixed(1)
                }
                return label;

              // return `${label}: ${tooltipItem.xLabel.toFixed(1)}`;
            }
          }
        },
          // Resize Point images when the chart size changes.
          onResize: (chart, newSize) => {
            this.resizePoints(newSize.width, this.chartData.datasets)
          },
      }
    };
  },
  mounted() {
      if (this.component.hasOwnProperty('chart-options')) {
          Object.keys(this.component['chart-options']).forEach(opt => {
              this.options[opt] = this.component['chart-options'][opt]
          })
      }
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

      // NOTE: Rendering the chart does NOT trigger the resize event and there is no 'render' event in ChartJS
    this.chartData.datasets = this.resizePoints(this.$el.clientWidth, this.chartData.datasets)
    this.renderChart(this.chartData, this.options);
    // TODO - HACK - This fixes the issue where firefox fails to render plots with a fixed aspect ratio until they are
    //               resized or hovered over.
      setTimeout(() => {
          this.renderChart(this.chartData, this.options)
      }, 200)
  },
    methods: {
      resizePoints(width, datasets) {
         datasets.forEach(dataset => {
              // See if we set a pointStyle and if it has a width we can adjust)
              let scale = 1
              if (dataset.hasOwnProperty('pointScale')) {
                  scale = dataset.pointScale

              }
              if (dataset.hasOwnProperty('pointStyle')) {
                  dataset.pointStyle.width = Math.max(5, width/25) * scale
                  dataset.pointStyle.height = Math.max(5, width/25) * scale
              }
              dataset.pointRadius = Math.max(15, width/50) * scale
              dataset.pointHoverRadius = Math.max(20, width/45) * scale
          })
          return datasets

          // chart.update()
      }
    },
    watch: {
        // ChartJS does NOT dynamically update if the legend config changes, so we have to redraw manually.
        'options.legend.display': function() {
            this.chartData.datasets = this.resizePoints(this.$el.clientWidth, this.chartData.datasets)
            this.renderChart(this.chartData, this.options);
        }

    }
};
