<template>
  <div class="horizontal-chart-container" v-if="this.current_cluster">
    <BarChart :chart-data="datacollection" :component="component" :style="styles" :statistic="statistic"
    ref="chart"></BarChart>
  </div>
</template>

<script>
import BarChart from '@/components/reporting/charts/BarChart.js';
import { getGraphColours } from "../../translation/cluster_colour_codes";

export default {
  name: 'HorizontalComparison',
  components: {
    BarChart
  },
  props: ['component', 'dataset', 'current_cluster', 'statistic', 'expand'],
  data() {
    return {
      datacollection:{ labels:[], datasets: [] },
      styles: {
        height: '100%',
        width: '100%',
        position: 'relative',
      }
    }
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      let data = [];
      let absData = []
      let sum_of_data = 0;
      for (let statistic of this.component['data-sources']) {
        if (this.current_cluster.hasOwnProperty(statistic)) {
          absData.push(this.current_cluster[statistic])
          sum_of_data += this.current_cluster[statistic]
        } else {
          absData.push(0)
        }
      }

      if (this.component['data-type'] === 'percentage') {
        for (let i = 0; i < absData.length; i++) {
          data[i] = ((100 * absData[i]) / sum_of_data).toFixed(0);
        }
      } else {
        data = absData
      }

      let clusterName = this.$store.state.dashboard.selectedCluster
      if (isNaN(parseInt(clusterName))) {
        clusterName = 'Total'
      } else {
        clusterName = `Cluster ${parseInt(clusterName) + 1}`
      }

      let labels = this.component['data-labels']
      if (!labels) {
        labels = this.component['data-sources']
      }

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: clusterName,
            data: data,
            absData: absData,
            backgroundColor: getGraphColours(data.length),
            borderColor: getGraphColours(data.length),
            borderWidth: 1,
            barThickness: 20,
          }
        ]
      }

      // Optionally show a shadow of the average across all datasets for each data source item
      if (this.$store.state.dashboard.selectedCluster !== 'total' &&
          this.component.hasOwnProperty('options') && this.component.options.hasOwnProperty('show-average')) {

        let clusters = Object.keys(this.dataset.cluster_information).filter(x => x !== 'total')

        let abs_data_avgs = this.component['data-sources'].map(dataSourceName => {
          let totalValue = clusters.reduce((sum, clusterId) => {
            return sum + this.dataset.cluster_information[clusterId][dataSourceName]
          }, 0)

          return totalValue / clusters.length
        })

        let data_avg;
        if (this.component['data-type'] === 'percentage') {
          let sum = abs_data_avgs.reduce((result, value) => result + value, 0)
          data_avg = abs_data_avgs.map(x => ((100 * x) / sum).toFixed(0))
        } else {
          data_avg = abs_data_avgs
        }

        this.datacollection.datasets.push({
              label: 'Average',
              data: data_avg,
              absData: abs_data_avgs,
              backgroundColor: 'rgba(0, 0, 0, 0.25)',
              // borderColor: getGraphColours(data.length),
              borderWidth: 1,
              barThickness: 20,
            })
      }

      console.log('Bar Data: ', this.datacollection)
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    }
  },
  watch: {
    current_cluster: function () {
      this.fillData()
    },
    expand: function (expand) {
      this.$refs.chart.options.legend.display = expand

    }
  }
};
</script>

<style>
.horizontal-chart-container {
  height: 100%;
  width: 100%;
}
</style>
