<template>
  <div class="map">
    <gmap-map
      :center="center"
      :zoom="12"
      style="width:100%;  height: 100%;"
      v-bind:options="mapStyle"
      ref="mapRef"
    >
<!--      POI Markers-->
      <div v-if="showPOIs">
        <gmap-marker
            :key="index"
            v-for="(m, index) in poiMarkers"
            :position="m.position"
            :title="m.title"
        ></gmap-marker>
    </div>
    </gmap-map>
    <!-- const colour = get_colour(current_square.label);
    const square = new google.maps.Polygon({
        map,
        fillColor: colour,
        fillOpacity: 1,
        strokeOpacity: 0.0,
        paths: current_square.path,
        indexID: current_square.label,
        number: rectangles.length,
    });
    rectangles.push(square);
    google.maps.event.addListener(square, 'click', () => {
        on_square_click(square);
    }); -->
<!--    Floating POI Control-->
    <div class="poi-control clickable" @click="togglePOIs">
      <font-awesome-icon class="poi-icon" icon="map-marker-alt" :class="{'poi-disabled': !showPOIs}"
                         v-b-tooltip title="Toggle POI Markers"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
import colours, {grey_color} from '@/translation/cluster_colour_codes.js';

export default {
  name: 'DashboardMap',
  props: ['geojsondata', 'dashboard', 'poidata'],
  data() {
    return {
      default_options: {},
      markers: [],
      places: [],
      currentPlace: null,
      mapStyle: {
        styles: [
          {
            elementType: 'geometry',
            stylers: [{ saturation: -100 }]
          }
        ]
      },
      selectedClusterId: 0,
      poiMarkers: [],
      showPOIs: false
    };
  },

  computed: {
    center() {
      if (this.dashboard) {
        return { lat: this.dashboard.centre[0], lng: this.dashboard.centre[1] };
      } else {
        return { lat: -38.3687, lng: 142.4982 };
      }
    }
  },

  mounted() {
    if (this.$store.state.dashboard.selectedCluster) {
      this.selectedClusterId = this.$store.state.dashboard.selectedCluster
    }
    // Map is loaded async, so we need to wait...
    this.$refs.mapRef.$mapPromise.then((map) => {
      // GeoJSON styling is decided by either a static value or a function
      map.data.setStyle((feature) => {
        let id = feature.getId()  // Have to use a function to lookup the id
        return this.unselectedStyle(id)
      })
      // Add GeoJSON feature click event listener
      map.data.addListener('click', (event) => {
        this.set_cluster_to(event.feature.getId())
      })

      this.map = map
      this.loadGeoJSON(this.selectedClusterId)
      this.loadPOIs(this.poidata)
    })
  },

  methods: {
    unselectedStyle (clusterId) {
      return {
        fillColor: this.get_colour(clusterId),
        strokeColor: this.get_colour(clusterId),
        strokeOpacity: 0.4,
        fillOpacity: 0.3,
        clickable: true
      }
    },
    selectedStyle (clusterId) {
      return {
        fillColor: this.get_colour(clusterId),
        strokeColor: this.get_colour(clusterId),
        strokeOpacity: 0.85,
        fillOpacity: 0.85,
        clickable: true
      }
    },
    get_colour(label) {
      return colours[label % colours.length];
    },
    set_cluster_to(new_cluster_id) {
      // this.$emit("set_current_cluster_id", new_cluster_id);
      this.$store.commit('dashboard/selectCluster', new_cluster_id)
    },
    loadGeoJSON () {
      let shapes = {}

      for (let clusterId of Object.keys(this.geojsondata)) {
        let shape = this.map.data.addGeoJson(this.geojsondata[clusterId], {idPropertyName: 'label'})
        shapes[clusterId] = shape
      }
      this.shapes = shapes
    },
    updateStyling(selectedClusterId) {
      // Clear Unselected Styles
      for (let shapeId of Object.keys(this.shapes)) {
        this.shapes[shapeId].forEach(x => this.map.data.revertStyle(x))

      }
      // Override Style for selected Cluster
      if (selectedClusterId !== 'total') {
        this.shapes[selectedClusterId].forEach(x => this.map.data.overrideStyle(x, this.selectedStyle(selectedClusterId)))
      }

    },
    addPOIMarker(poiData) {
      const position = { lat: poiData.latitude, lng: poiData.longitude }
     this.poiMarkers.push({
        position: position,
        map: this.map,
        title: poiData.tooltip
      })
    },
    loadPOIs(poiData) {
      if (poiData) {
        this.poiMarkers = []
        poiData.forEach(poi => this.addPOIMarker(poi))
      }
    },
    togglePOIs() {
      this.showPOIs = !this.showPOIs
    }
  },
    watch: {
      '$store.state.dashboard.selectedCluster': function (newClusterId) {
        this.selectedClusterId = newClusterId
        this.updateStyling(newClusterId)
        let poiFilter = parseInt(newClusterId)
        if (isNaN(poiFilter)) {
          this.loadPOIs(this.poidata)
        } else {
          this.loadPOIs(this.poidata.filter(x => x.label === poiFilter))
        }
        console.log('POI Filter: ', poiFilter)
      },
      geojsondata: function (newGeoData) {
        this.loadGeoJSON()
      },
      poidata: function (newPoiData) {
        this.loadPOIs(newPoiData)
      }
    }
}
</script>
<style scoped>
.map {
  background-color: lightgrey;
  width: 100%;
  height: 400pt;
}

.poi-control {
  background: white;
  width: 50px;
  position: relative;
  top: -72px;
  left: 10px;
  text-align: center;
  z-index: 5;
  color: #e84235;
  font-size: 2em;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}

.poi-icon:focus {
  border: none;
  outline: none !important;
}

.poi-control:hover {
  color: #b71e00;
}

.poi-disabled {
  color: #6c6c6c;
}

.poi-disabled:hover {
  color: #545454;
}

</style>
