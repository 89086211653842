// TODO: This should all be in the database

// Maps job codes to descriptions
const human_readable_strings = {
  P_Mining_T: 'Mining',
  P_Manufact_T: 'Manufacturing',
  P_El_Gas_Wt_Waste_T: 'Electricity, Gas, Water and Waste Services',
  P_Constru_T: 'Construction',
  P_WhlesaleTde_T: 'Wholesale Trade',
  P_RetTde_T: 'Retail Trade',
  P_Accom_food_T: 'Accomodation',
  P_Trans_post_wrehsg_T: 'Transport (postal and warehousing)',
  P_Info_media_teleco_T: 'Information Media and Telecommunications',
  P_Fin_Insur_T: 'Financial and Insurance Services',
  P_RtnHir_REst_T: 'Rental hiring and Real Estate',
  P_Pro_scien_tec_T: 'Professional Scientific and Technical Services',
  P_Admin_supp_T: 'Administrative and Support Services',
  P_Public_admin_sfty_T: 'Public Administraction and Safety',
  P_Educ_trng_T: 'Education and Training',
  P_HlthCare_SocAs_T: 'Health care and social assistance',
  P_Art_recn_T: 'Arts and Recreation',
  P_Oth_scs_T: 'Other services',
  P_ID_NS_T: 'Not stated',
  P_Min_Tot: 'Mining',
  P_Manf_Tot: 'Manufacturing',
  P_Elec_gas_wtr_wst_sv_Tot: 'Electricity, Gas, Water and Waste Services',
  P_Con_Tot: 'Construction',
  P_Wales_Tot: 'Wholesale Trade',
  P_Re_trd_Tot: 'Retail Trade',
  P_Acc_food_svc_Tot: 'Accomodation',
  P_Trp_pos_wh_Tot: 'Transport (postal and warehousing)',
  P_In_med_tcom_Tot: 'Information Media and Telecommunications',
  P_Fin_in_svc_Tot: 'Financial and Insurance Services',
  REst_To: 'Rental hiring and Real Estate',
  P_Prof_sc_tech_serv_Tot: 'Professional Scientific and Technical Services',
  P_Adm_sup_serv_Tot: 'Administrative and Support Services',
  P_PubAd_saf_Tot: 'Public Administraction and Safety',
  P_Ed_trng_Tot: 'Education and Training',
  P_Hlth_Tot: 'Health care and social assistance',
  P_Arts_rec_svc_Tot: 'Arts and Recreation',
  P_Oth_svc_Tot: 'Other services',
  P_ID_NS_Tot: 'Not stated',

  "clerical_admins_percentage": "Clerical/Administrators",
  "personal_services_percentage": "Personal Services",
  "labourers_percentage": "Labourers",
  "machine_operators_percentage": "Machine Operators",
  "managers_percentage": "Managers",
  "occupation_unknown_percentage": "Unknown",
  "professionals_percentage": "Professionals",
  "sales_percentage": "Sales",
  "technical_trades_percentage": "Technical Trades"
};

export default human_readable_strings;