<template>
  <div>
    <editor
        id="scenario_modelling"
        v-model="component['ruleset']"
        @init="editorInit"
        lang="python"
        theme="crimson_editor"
        height="100pt"
      ></editor>
    <b-table-simple
      striped
      hover
      caption-top
      v-if="required_variables.length > 0"
    >
      <caption>
        Your scenario requires additional variable values not present in the
        data. Please enter them below.
      </caption>
      <b-thead>
        <b-tr>
          <b-th>Variable</b-th>
          <b-th>Value</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="variable in required_variables" v-bind:key="variable">
          <b-td>{{ variable }}</b-td>
          <b-td><b-input v-model="variable_values[variable]"></b-input></b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-button @click="compute_scenario()">Compute</b-button>
    <b-button @click="reset_scenario_results()" variant="warning"
      >Clear Results</b-button
    ><br>
    <small
      >Please note updated scenarios do not save. Edit the dashboard to make
      changes permanent.</small
    >
    <b-table-simple striped hover caption-top v-if="show_results">
      <caption>
        Your scenario requires additional variable values not present in the
        data. Please enter them below.
      </caption>
      <b-thead>
        <b-tr>
          <b-th>Statistic</b-th>
          <b-th
            v-for="(cluster, cluster_num) in this.dataset[
              'cluster_information'
            ]"
            :key="cluster_num"
          >
            Cluster {{ cluster_num }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="statistic in result_statistics" v-bind:key="statistic">
          <b-td>{{ statistic }}</b-td>
          <b-td
            v-for="(cluster, cluster_num) in dataset['cluster_information']"
            :key="cluster_num"
            >{{
              format_scenario_result_statistic(
                scenario_results[cluster_num]['results'][statistic],
                scenario_results[cluster_num]['initial_value'][statistic]
              )
            }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
if (typeof String.prototype.trim === 'undefined') {
  String.prototype.trim = function() {
    return String(this).replace(/^\s+|\s+$/g, '');
  };
}

export default {
  name: 'ScenarioModel',
  props: ['component', 'dataset'],
  components: {
    editor: require('vue2-ace-editor')
  },

  data() {
    return {
      variable_values: {},
      scenario_results: {},
      result_statistics: new Set(),
      show_results: false
    };
  },

  computed: {
    parsed_rules() {
      try {
        return this.parse_rules(this.component['ruleset']);
      } catch (error) {
        return [];
      }
    },
    required_variables() {
      if (this.parsed_rules.length === 0) {
        return [];
      }
      return this.get_required_tokens(
        this.parsed_rules,
        this.dataset['cluster_information'][0]
      );
    }
  },

  methods: {
    editorInit: function() {
      require('brace/ext/language_tools'); //language extension prerequsite...
      require('brace/mode/python');
      require('brace/mode/less');
      require('brace/theme/crimson_editor');
    },
    format_scenario_result_statistic(new_value, old_value) {
        console.log(new_value, old_value)
        if (new_value === old_value || !old_value){
            return this.nicely_format_number(new_value);
        }
        let percentage_change = 100 * (new_value - old_value) / old_value;
        let nice_new = this.nicely_format_number(new_value);
        let nice_perc = this.nicely_format_number(percentage_change);
        let direction = "⬇";
        if (new_value > old_value){
            direction = "⬆";
        }
        return `${nice_new} (${direction} ${nice_perc}%)`
    },
    nicely_format_number(value){
        let abs = Math.abs(value);
        let parsed_value = 0;
        // I don't use round values like 100 or 10 here, because due to floating
        // point numbers, you end up with different formatting for the same delta.
        // For instance, two clusters with a 10% decrease in value will get different
        // rounding just due to whether the floating point error puts it just above
        // or just below 10%. Using non-round numbers doesn't change this, but makes
        // it less likely the user will see. For instance, a common rule would be
        // "reduce this value by 10% and see what happens", it is much less likely
        // to get "reduce this value by 10.001% and see what happens"
        if (abs >= 100.001){
            // No decimal places above 100
            parsed_value = parseInt(value);
        }else if (abs > 10.001){
            parsed_value = parseFloat(value).toPrecision(1);
        }else if (abs > 1.001){
            parsed_value = parseFloat(value).toPrecision(2);
        }else{
            parsed_value = parseFloat(value).toPrecision(3);
        }

        return parsed_value;
    },
    reset_scenario_results() {
      this.show_results = false;
      this.scenario_results = {};
      this.result_statistics = new Set();
    },

    compute_scenario() {
      this.scenario_results = {};
      for (let cluster_num in this.dataset['cluster_information']) {
        this.scenario_results[cluster_num] = this.apply_rules(
          this.parsed_rules,
          this.dataset['cluster_information'][cluster_num],
          this.variable_values
        );
      }
      this.show_results = true;
      return this.scenario_results;
    },
    parse_rules(rules_str) {
      let rule_list = rules_str.match(/[^\r\n]+/g);
      let parsed_rules = [];
      for (let rule_line of rule_list) {
        rule_line = rule_line.trim();
        if (rule_line.length === 0) {
          continue;
        } else if (rule_line.startsWith('#')) {
          // Comment, ignore line
          continue;
        }
        parsed_rules.push(this.parse_single_rule(rule_line));
      }
      return parsed_rules;
    },

    /**
     * Finds all the tokens/symbols that aren't in data. The user needs to provide those
     *
     * Only checks the RHS - the LHS are dependent.
     *
     * TODO: If one rule give a LHS that a later rule's RHS uses, we don't need it in this list
     *
     * @param parsed_rules
     * @param data
     */
    get_required_tokens(parsed_rules, data) {
      let required_tokens = [];
      for (let rule of parsed_rules) {
        for (let symbol of rule['independent']) {
          if (!(symbol['symbol_type'] === 'symbol')) {
            // Not a symbol we need to lookup
            continue;
          }
          if (!data[symbol['value']]) {
            // Not present in the data, so the user will need to give a value (unless we already have it
            if (!required_tokens.includes(symbol['value'])) {
              required_tokens.push(symbol['value']);
            }
          }
        }
      }
      return required_tokens;
    },

    parse_single_rule(rule_line) {
      let operators = ['*', '+', '-', '/'];
      let formula_split = rule_line.match(/[^=]+/g);

      // Parse the LHS of the equation, which must be a single token
      let lhs = this.parse_single_token(formula_split[0]);

      // Parse the RHS of the equation, which is a complex equation
      let rhs_split = formula_split[1].match(/[^\s]+/g);
      let rhs = [];

      for (let token of rhs_split) {
        token = token.trim();
        if (operators.includes(token)) {
          rhs.push({
            operator: token,
            raw: token,
            symbol_type: 'operator'
          });
        } else {
          rhs.push(this.parse_single_token(token));
        }
      }

      return {
        raw: rule_line,
        dependent: lhs,
        independent: rhs
      };
    },

    /**
     * Parses a single token, including direct modifiers.
     *
     * Examples:
     *
     * interest_rate
     * Single token, replaced with interest_rate symbol
     *
     * -income
     * Single token, the negation of the income
     *
     * mortgage%
     * Single token, the percentage change of mortgage
     *
     * -motgage%
     * Single token, the negation of the percentage change of mortgage
     *
     * 1000
     * Single token, simply the value 1000
     *
     *
     * TODO:
     * - Ensure that RHS tokens don't have percentage change
     *
     * @param token
     * @returns \{\{symbol: *, multiplier: number, delta_type: string, raw: *\}\}
     */
    parse_single_token(token) {
      token = token.trim();

      // Negation at the start of the token
      let multiplier = 1;
      if (token.startsWith('-')) {
        multiplier = -1;
      }

      let delta_type = 'absolute';
      if (token.endsWith('%')) {
        delta_type = 'percentage';
      }

      let symbol = token
        .replace(/-/g, '')
        .replace(/%/g, '')
        .trim();

      // Try parse as a float. if that succeeds, we have a number, not a symbol
      let symbol_type = 'symbol';
      let asfloat = parseFloat(symbol);
      if (asfloat) {
        symbol_type = 'number';
        symbol = asfloat;
      }

      return {
        raw: token,
        value: symbol,
        multiplier: multiplier,
        delta_type: delta_type,
        symbol_type: symbol_type
      };
    },

    apply_rules(parsed_rules, data, scenario) {
      let results = {};
      let first_seen_value = {}; // What was the value the first time we saw a new token?
      for (let rule of parsed_rules) {
        let _ret = this.apply_single_rule(
          results,
          rule,
          data,
          scenario,
          first_seen_value
        );
        results = _ret['results'];
        first_seen_value = _ret['initial_value'];
      }
      return { results: results, initial_value: first_seen_value };
    },

    apply_single_rule(results, rule, data, scenario) {
      let first_seen_value = {};
      // Build RHS rule from independent tokens
      let rhs = '';
      let symbol;
      for (symbol of rule['independent']) {
        rhs += ' '; // Put a space around to stop tokens running into each other
        if (symbol['operator']) {
          // An operator, like + - * /
          rhs += symbol['operator'];
        } else if (symbol['symbol_type'] === 'number') {
          // Just a hard coded value, like 1000
          rhs += symbol['value'];
        } else {
          // Lookup by symbol name. first in results, then scenario, then in data
          let value = null;
          if (results[symbol['value']]) {
            value = results[symbol['value']];
            rhs += symbol['multiplier'] * value;
          } else if (scenario[symbol['value']]) {
            value = scenario[symbol['value']];
            rhs += symbol['multiplier'] * value;
          } else if (data[symbol['value']]) {
            value = data[symbol['value']];
            rhs += symbol['multiplier'] * value;
          }
          if (!first_seen_value[symbol['value']]) {
            // If we haven't seen this token before, record it's initial value
            first_seen_value[symbol['value']] = value;
          }
        }
        rhs += ' '; // Put a space around to stop tokens running into each other
      }
      let modifier_value = eval(rhs);

      // Apply that to the LHS
      // Get the LHS starting value. First from results, then from scenario, then from data
      let lhs = 0;
      symbol = rule['dependent'];
      if (results[symbol['value']]) {
        lhs += results[symbol['value']];
      } else if (scenario[symbol['value']]) {
        lhs += scenario[symbol['value']];
      } else if (data[symbol['value']]) {
        lhs += data[symbol['value']];
      }

      // Alter by value, either as a percentage or absolute
      if (symbol['delta_type'] === 'percentage') {
        lhs *= modifier_value / 100;
      } else {
        lhs = modifier_value;
      }
      // Update and return results
      if (!first_seen_value[symbol['value']]) {
        // If we haven't seen this token before, record it's initial value
        first_seen_value[symbol['value']] = lhs;
      }
      results[symbol['value']] = lhs;
      this.result_statistics.add(symbol['value']);
      return { results: results, initial_value: first_seen_value };
    }
  }
};
</script>

<style scoped>
#scenario_modelling{
  font-size: 14pt;
}
</style>
