<template>
  <div class="markdown_cell">
    <VueShowdown :markdown="content"/>
  </div>
</template>

<script>
import VueShowdown from 'vue-showdown'

export default {
  name: "MarkdownComponent",
  props: ['component'],
  computed: {
    content() {
      if (typeof this.component.body === 'string') {
        return this.component.body
      } else if (this.component.body.hasOwnProperty(this.$store.state.dashboard.selectedCluster)) {
        return this.component.body[this.$store.state.dashboard.selectedCluster]
      } else {
        return 'No Content for selected cluster'
      }
    }
  }
}
</script>

<style>
.markdown_cell {
  text-align: start;
  max-width: 650pt;
  margin: auto;

  h1 {
    font-size: 4em;
  }
}

.markdown_cell img {
  max-width: 100% !important;
  margin: auto;
}
</style>
