const colours = [
    'rgba(2, 9, 167, 0.5)',
    'rgba(77, 188, 145, 0.5)',
    'rgba(101, 204, 243, 0.5)',
    'rgba(225, 228, 76, 0.5)',
    'rgba(250, 175, 65, 0.5)',
    'rgba(242, 128, 142, 0.5)',
    'rgba(162, 157, 204, 0.5)',

]; // THat's 10, should be enough!

export const grey_color = 'rgba(58,58,58,0.5)';

/*
    Old Colours
 'rgba(0, 46, 93, 0.5)',
  'rgba(250, 162, 27, 0.5)',
  'rgba(83, 127, 64, 0.5)',
  'rgba(71, 72, 158, 0.5)',
  'rgba(139, 138, 195, 0.5)',
  'rgba(173, 98, 140, 0.5)',
  'rgba(44, 162, 165, 0.5)',
  'rgba(151, 193, 138, 0.5)',
  'rgba(221, 107, 118, 0.5)',
  'rgba(248, 213, 33, 0.5)'
 */

export const graphColoursPrimary = [
    '#025ea7',
    '#a29dcc',
    '#65ccf3',
    '#e1e44c'
]

/***
 * Get x number of graph colours
 * @param x
 */
export function getGraphColours (x) {
    let colours = []
    let colourIdx = 0
    for (let i = 0; i <= x; i++) {
        colours.push(graphColoursPrimary[colourIdx])
        colourIdx ++
        if (colourIdx === graphColoursPrimary.length) {
            colourIdx = 0
        }
    }
    return colours
}

export const graphColoursSecondary = [
    '#faaf41',
    '#4dbc91',
    '#f2808e'
]


export default colours;
