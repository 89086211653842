<template>
  <div>
    <Component
        v-if="component && componentLookup[component.type]"
        :is="componentLookup[component.type]"
        :component="component"
        :dataset="dataset"
        :current_cluster="current_cluster"
        :statistic="statistic"
        :expand="expand"
    >
    </Component>
    <div v-else>
      <h3>Unknown component:</h3>
      {{ component }}
    </div>
  </div>
</template>

<script>

import MarkdownComponent from '@/components/reporting/MarkdownComponent.vue';
import TableComponent from '@/components/reporting/TableComponent.vue';
import RankingTable from '@/components/reporting/RankingTable.vue';
import LabelComponent from '@/components/reporting/LabelComponent.vue';
import SimpleBarComponent from '@/components/reporting/SimpleBarComponent.vue';
import HorizontalComparison from '@/components/reporting/HorizontalComparison.vue';
import PieComponent from '@/components/reporting/PieComponent.vue';
import ScenarioComponent from '@/components/reporting/ScenarioComponent.vue';
import LinearModelComponent from '@/components/reporting/LinearModelComponent.vue';
import ButtonComponent from '@/components/reporting/ButtonComponent.vue';
import BubbleScatterComponent from '@/components/reporting/BubbleScatterComponent.vue';
import ComponentTesterComponent from '@/components/reporting/ComponentTesterComponent';
import FlatScatterComponent from "@/components/reporting/FlatScatterComponent";
import RadarComponent from "@/components/reporting/RadarComponent";
import HighlightsComponent from "@/components/reporting/HighlightsComponent";
import ReportParametersComponent from "@/components/reporting/ReportParametersComponent";

export default {
  name: 'ComponentPlacer',
  props: ['statistic', 'dataset', 'current_cluster', 'current_cluster_id', 'debug_mode', 'component', 'expand'],
  components: {
    MarkdownComponent,
    TableComponent,
    RankingTable,
    LabelComponent,
    SimpleBarComponent,
    HorizontalComparison,
    PieComponent,
    ScenarioComponent,
    LinearModelComponent,
    ButtonComponent,
    BubbleScatterComponent,
    ComponentTesterComponent,
    FlatScatterComponent,
    RadarComponent,
    HighlightsComponent,
    ReportParametersComponent
  },
  data() {
    return {
      componentLookup: {
        'table': TableComponent,
        'markdown': MarkdownComponent,
        'ranking-table': RankingTable,
        'label': LabelComponent,
        'bar': SimpleBarComponent,
        'horizontal-comparison': HorizontalComparison,
        'pie': PieComponent,
        'scenario': ScenarioComponent,
        'linear-model': LinearModelComponent,
        'button': ButtonComponent,
        'bubble-chart': BubbleScatterComponent,
        'flatscatter': FlatScatterComponent,
        'radar': RadarComponent,
        'component-tester': ComponentTesterComponent,
        'highlights': HighlightsComponent,
        'report-params': ReportParametersComponent
      }
    }
  }
}
</script>
