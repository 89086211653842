<template>
  <div class="button_holder">
    <b-button
      v-if="attributes"
      :class="attributes['class']"
      :variant="attributes['variant']"
      @click="attributes['onclick']"
    >
      {{ attributes['text'] }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: ['component', 'dataset', 'current_cluster', 'current_cluster_id'],
  computed: {
    attributes() {
      if (this.component['action'] === 'facebook_cluster_download') {
        return {
          class: 'action_button facebook_button',
          variant: 'outline-primary',
          text: 'Export for Facebook Ads',
          onclick: this.facebook_download,
        };
      } else if (this.component['action'] === 'csv_download') {
        {
          return {
            class: 'action_button csv_button',
            variant: 'outline-success',
            text: 'Export CSV',
            onclick: this.csv_download,
          };
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    facebook_download() {
      let grid_overlay = this.dataset['grid_information'];
      let addresses = [];
      let val = '';
      let count = 0;
      for (let i = 0; i < grid_overlay.length; i++) {
        if (grid_overlay[i]['label'] === this.current_cluster_id) {
          addresses.push(grid_overlay[i]['address']);
          count += 1;
          val += grid_overlay[i]['address'] + '\n';
        }
      }
      console.log('count', count);
      const a = document.createElement('a');
      window.URL = window.URL || window.webkitURL;
      if (window.URL && window.Blob && 'download' in a && window.atob) {
        // Do it the HTML5 compliant way
        const blob = new Blob([val], { 'content-type': 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        console.log(url);
        a.href = url;
        a.download = 'facebook_download.txt';
        document.body.appendChild(a);
        a.click();

        setTimeout(function () {
          /* On Firefox, you have to wait to remove the element, or the download doesn't happen
            https://stackoverflow.com/questions/30694453/blob-createobjecturl-download-not-working-in-firefox-but-works-when-debugging
             */
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 100);
      } else {
        console.debug('No go');
      }

      return false;
    },
    csv_download() {
      const a = document.createElement('a');
      window.URL = window.URL || window.webkitURL;
      if (window.URL && window.Blob && 'download' in a && window.atob) {
        // // Do it the HTML5 compliant way
        // console.log("CSV DATA:");
        // console.log(this.dataset['export'])
        const blob = this.base64ToBlob(
          this.dataset['exports']['csvdata'],
          'text/csv'
        );
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'clusters.csv';
        document.body.appendChild(a);
        a.click();

        setTimeout(function () {
          /* On Firefox, you have to wait to remove the element, or the download doesn't happen
            https://stackoverflow.com/questions/30694453/blob-createobjecturl-download-not-working-in-firefox-but-works-when-debugging
             */
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 100);
      } else {
        console.warning('CSV Download failed');
      }

      return false;
    },
    base64ToBlob(base64, mimetype, slicesize) {
      if (!window.atob || !window.Uint8Array) {
        // The current browser doesn't have the atob function. Cannot continue
        return null;
      }
      mimetype = mimetype || '';
      slicesize = slicesize || 512;
      const bytechars = atob(base64);
      const bytearrays = [];
      for (let offset = 0; offset < bytechars.length; offset += slicesize) {
        const slice = bytechars.slice(offset, offset + slicesize);
        const bytenums = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          bytenums[i] = slice.charCodeAt(i);
        }
        const bytearray = new Uint8Array(bytenums);
        bytearrays[bytearrays.length] = bytearray;
      }
      return new Blob(bytearrays, { type: mimetype });
    },
  },
};
</script>

<style>
.action_button {
  height: 60pt;
  margin: 8pt;
  font-size: 1.5em;
}

.button_holder {
}

.facebook_button {
}
</style>
