<template>
  <div class="label">
    {{ component['value'] }}
  </div>
</template>

<script>
export default {
  name: 'TableComponent',
  props: ['component']
};
</script>

<style>
.label {
  background-color: inherit;
  text-align: center;
}
</style>
