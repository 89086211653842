import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;


export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ['component'],
  data() {
    // const self = this;
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.labels[tooltipItem.index];

              let value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              let percentage_descriptor = '';
              if (this.component['data-type'] === 'percentage') {
                percentage_descriptor = '%';
              } else if (this.component['data-type'] &&
                  this.component['data-type'] === 'currency') {
                  label += '$' + tooltipItem.yLabel.toFixed(2)
              }
                return `${label}: ${value.toFixed(1)}${percentage_descriptor}`;
            }
          }
        }
      }
    };
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  }
};
