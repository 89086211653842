<template>
    <div class="table_cell">
        <b-table-simple striped hover responsive>
            <b-tbody>
                <b-tr v-for="(row, i) in component['rows']" v-bind:key="i">
                    <b-th v-if="row['header']" colspan="2"><h4>{{row['header']}}</h4></b-th>
                    <b-th v-if="row['description']">{{ row['description']}}</b-th>
                    <b-td v-if="row['meta-key']">
                        {{ dataset['metadata'][row['meta-key']] | run_analysis_on_value(row)}}
                    </b-td>
                    <b-td v-else-if="row['data-key']">
                        {{ current_cluster[row['data-key']]  | run_analysis_on_value(row)}}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>

export default {
    name: "TableComponent",
    props: ['component', 'dataset', 'current_cluster'],
}
</script>

<style scoped>

.table_cell{
    width: 60%;
    min-width: 200px;
    margin: auto;
}

</style>
