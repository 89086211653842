import { Radar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
import { Chart } from "chart.js";

export default {
  extends: Radar,
  mixins: [reactiveProp],
  props: ['component'],
  data() {
    return {
      options: {
          interaction: {
              // mode: 'point',
              // intersect: false
          },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
            position: 'right'
        },
          scale: {
              ticks: {
                  min: 0,
                  max: 100
              }
          },
        tooltips: {
          callbacks: {
            title: (tooltipItem, data) => {
                return data.datasets[tooltipItem[0].datasetIndex].label || '';
            },
            labelColor: (tooltipItem, data) => {
                  return {
                      backgroundColor: this.chartData.datasets[tooltipItem.datasetIndex].backgroundColor
                  }
              },
            label: (tooltipItem, data) => {
                let rawValue = data.datasets[tooltipItem.datasetIndex].raw_data[tooltipItem.index]
              return data.datasets[tooltipItem.datasetIndex].raw_data[tooltipItem.index].toFixed(1) || ''
            }
          }
        },
          onClick: (e, elements) => {
              // ChartJS does NOT support clickable labels. The labels are part of the 2D canvas that the chart
              // is rendered onto, so we need to reverse-engineer the drawing code from ChartJS to work out where
              // the XY of the click falls.
              // https://stackoverflow.com/questions/50470784/make-chart-js-radar-labels-clickable
              var helpers = Chart.helpers;
              var scale = this.$data._chart.scale;
              var opts = scale.options;
              var tickOpts = opts.ticks;
              var ctx = this.$data._chart.ctx

              // Position of click relative to canvas.
              var mouseX = e.offsetX;
              var mouseY = e.offsetY;

              var labelPadding = 5; // number pixels to expand label bounding box by

              // get the label render position
              // calcs taken from drawPointLabels() in scale.radialLinear.js
              var tickBackdropHeight = (tickOpts.display && opts.display) ?
                  helpers.valueOrDefault(tickOpts.fontSize, Chart.defaults.global.defaultFontSize)
                  + 5 : 0;
              var outerDistance = scale.getDistanceFromCenterForValue(opts.ticks.reverse ? scale.min : scale.max);
              for (var i = 0; i < scale.pointLabels.length; i++) {
                  // Extra spacing for top value due to axis labels
                  var extra = (i === 0 ? tickBackdropHeight / 2 : 0);
                  var pointLabelPosition = scale.getPointPosition(i, outerDistance + extra + 5);

                  // get label size info.
                  // TODO fix width=0 calc in Brave?
                  // https://github.com/brave/brave-browser/issues/1738
                  var plSize = scale._pointLabelSizes[i];

                  // get label textAlign info
                  var angleRadians = scale.getIndexAngle(i);
                  var angle = helpers.toDegrees(angleRadians);
                  var textAlign = 'right';
                  if (angle == 0 || angle == 180) {
                      textAlign = 'center';
                  } else if (angle < 180) {
                      textAlign = 'left';
                  }

                  // get label vertical offset info
                  // also from drawPointLabels() calcs
                  var verticalTextOffset = 0;
                  if (angle === 90 || angle === 270) {
                      verticalTextOffset = plSize.h / 2;
                  } else if (angle > 270 || angle < 90) {
                      verticalTextOffset = plSize.h;
                  }

                  // Calculate bounding box based on textAlign
                  var labelTop = pointLabelPosition.y - verticalTextOffset - labelPadding;
                  var labelHeight = 2 * labelPadding + plSize.h;
                  var labelBottom = labelTop + labelHeight;

                  var labelWidth = plSize.w + 2 * labelPadding;
                  var labelLeft;
                  switch (textAlign) {
                      case 'center':
                          var labelLeft = pointLabelPosition.x - labelWidth / 2;
                          break;
                      case 'left':
                          var labelLeft = pointLabelPosition.x - labelPadding;

                          break;
                      case 'right':
                          var labelLeft = pointLabelPosition.x - labelWidth + labelPadding;
                          break;
                      default:
                          console.log('ERROR: unknown textAlign ' + textAlign);
                  }
                  var labelRight = labelLeft + labelWidth;

                  // Render a rectangle for testing purposes
                  // ctx.save();
                  // ctx.strokeStyle = 'blue';
                  // ctx.lineWidth = 1;
                  // ctx.strokeRect(labelLeft, labelTop, labelWidth, labelHeight);
                  // ctx.restore();

                  // compare to the current click
                  if (mouseX >= labelLeft && mouseX <= labelRight && mouseY <= labelBottom && mouseY >= labelTop) {
                      // alert(scale.pointLabels[i]+' clicked');
                      // console.log(scale.pointLabels[i]+' clicked')
                      // Break loop to prevent multiple clicks, if they overlap we take the first one.
                      this.$emit('label-click', scale.pointLabels[i])
                      break;
                  }
              }
          },
          plugins: [

          ]
      }
    }
  },
  mounted() {
      if (this.component.hasOwnProperty('chart-options')) {
          Object.keys(this.component['chart-options']).forEach(opt => {
              this.options[opt] = this.component['chart-options'][opt]
          })
      }
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
    watch: {
        // ChartJS does NOT dynamically update if the legend config changes, so we have to redraw manually.
        'options.legend.display': function() {
            this.renderChart(this.chartData, this.options);
        }

    }
};
